import React from "react"
import styled from "styled-components"
import { Link, graphql  } from "gatsby"

import Page from "../components/layout/Page"
import SEO from "../components/seo"
import GoogleImage from "../components/GoogleImage"
import PageHeader  from "../components/layout/PageHeader"
import HoverButton from "../components/buttons/HoverButton"

import { devices } from "../settings"

const Content = styled.div`
    margin-left: 20px;
    padding-bottom: 50px;

    @media ${devices.mobile} {
        margin-left: 0px;
    }

    h3 {
        font-family: "Roboto Slab", "Helvetica", "Arial", sans-serif;
    }
    h4 {
        background-color: #e0e0e0;
        padding: 8px;
        border-radius: 4px;
    }
    > p, > div {
        margin-left: 20px;
    }
    > div img {
        margin: 20px;

        @media ${devices.mobile} {
            margin: 10px 0px;
        }        
    }
`

export default function StandardLetters({ data }) {
    const product = data.products.edges[0].product;
    
    return (
        <>
            <SEO page="productoption" title="Standard Letters and Shapes" />

            <Page 
                transparent
                padding={0}
                breadcrumb={[
                    {title: "Home", url:"/"},
                    {title: "Products", url:"/products/"},                    
                    {title: product.category, url: "/products/" + product.category_name + "/"},
                    {title: product.title, url: "/products/" + product.url + "/"},
                    {title: "Standard Letters and Shapes", url: null}
                ]}
                >
                    <PageHeader 
                        title="Standard Letters and Shapes"
                    />
                    
                    <Content>
                        <h3>Standard Type Styles</h3>
                        <p>
                        This list of Type Style or Font and Shapes is for our three dimensional products and lit products. Any graphics applied to 
                        the faces of our products have many more graphics or fonts available. Since our Standard Styles and Shapes are pre-engineered, 
                        we can move to production slightly faster than a non standard styles. This will save you a little money using standard styles, 
                        simply because it saves us time.
                        </p>

                        <h3>Standard Shapes</h3>
                        <p>
                            Actually every sign will build is "Custom", we rarely make the same exact sign twice, unless it is one of our repeat 
                            customers. Their is very little difference building your Shape then our own. The colors and face graphic are 
                            infinitely variable so that you can dial in the look you are after.
                        </p>
                        <p>
                            We currently offer 10 standard type styles and about 60 standard shapes. If you have a type style or shape 
                            that you need that we do not offer as standard, we can produce your style with the same quality, speed 
                            and fair price that we offer for our standard products.
                        </p>
                        <p>
                            <Link to={"/products/" + product.url + "/nonstandard/"}>
                                <HoverButton
                                    label="See Non-Standard Shape and Type Styles"
                                    color="blue"
                                />
                            </Link>
                        </p>

                        <br />
                        <p>
                            We are also adding more standard shapes and styles from time to time.
                        </p>
                        <br />

                        <h4>STANDARD BOLD</h4>
                        <GoogleImage src="type_style-HLVBR.jpg" required lazy alt="Helvetica Bold Standard EZLit Type Style" />

                        <h4>SERIF BOLD</h4>
                        <GoogleImage src="type_style-TIMBR.jpg" required lazy alt="Time Bold Standard EZLit Type Style" />

                        <h4>STANDARD CONDENSED</h4>
                        <GoogleImage src="type_style-HLVBC.jpg" required lazy alt="Helvetica Condensed Bold Standard EZLit Type Style" />

                        <h4>STANDARD ROUND</h4>
                        <GoogleImage src="type_style-HLVRR.jpg" required lazy alt="Helvetica Round Standard EZLit Type Style" />

                        <h4>BOXER</h4>
                        <GoogleImage src="type_style-Rocky.jpg" required lazy alt="Rockwell Bold Standard EZLit Type Style" />

                        <h4>FREE SCRIPT</h4>
                        <GoogleImage src="type_style-FREMI.jpg" required lazy alt="Freehand Script Standard EZLit Type Style" />

                        <h4>CAPE COD</h4>
                        <GoogleImage src="type_style-Lobster.jpg" required lazy alt="Lobster Bold Standard EZLit Type Style" />

                        <h4>BRUSH</h4>
                        <GoogleImage src="type_style-BRSBI.jpg" required lazy alt="Brush Script Standard EZLit Type Style" />

                        <h4>STANDARD LIT SHAPES</h4>
                        <GoogleImage src="Logo_Shape-1024x245.jpg" required lazy alt="Lit Shapes Type Style" />

                    </Content>

            </Page>
        </>
    )
}

export const query = graphql`
    query($productName: String!) {
        products: allMysqlProducts(filter: {name: {eq: $productName}}) {
            edges {
                product: node {
                    id
                    name
                    url
                    title
                    category
                    category_name
                }
            }
        }
    }`